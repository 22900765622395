<template>
  <div class="o-filter">
    <div class="row no-gutter bg-info pt-5 pb-2">
      <div class="col-12 pl-5 pr-5 o-filter__col">
        <form ref="form" class="row no-gutter" @submit.stop.prevent="setFilter">
          <label
            for="search"
            class="text-primary font-weight-bold mb-1 o-filter__label"
            >Cerca attività:</label
          >
          <b-form-input
            id="search"
            placeholder="Cerca"
            type="text"
            v-model="getDataFilter.search"
            class="mb-4"
          ></b-form-input>

          <date-range @change-range="getDate"></date-range>

          <b-list-group class="w-100">
            <p class="text-primary font-weight-bold mb-1  o-filter__label">
              Tipologia
            </p>
            <b-list-group-item
              class="d-flex justify-content-between align-items-center w-100"
              v-for="date in listTypeActivity"
              :key="date.id"
            >
              <b-form-checkbox
                :id="date.activity.code"
                :name="date.activity.code"
                v-model="getDataFilter.checkedType"
                :value="date.activity.code"
              >
                <span class="w-100 d-flex align-items-center">
                  <b-badge class="m-badge ml-2 mr-2"
                    ><span>{{ date.activity.code }}</span></b-badge
                  >
                  <small>{{ date.activity.name }}</small>
                </span>
              </b-form-checkbox>
            </b-list-group-item>
          </b-list-group>

          <b-button block type="submit" variant="primary" class=" mt-3 w-100"
            >Applica filtri</b-button
          >
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import DateRange from "./moduls/DataRange.vue";
export default {
  emits: ["change-filter"],
  components: { DateRange },

  name: "AppActivityFilter",
  data() {
    return {
      activeFilter: {
        search: "",
        checkedType: [],
        dataStart: "01/12/2018",
        dataEnd: "01/12/2050",
      },

      getDataFilter: {
        search: "",
        checkedType: [],
        dataStart: "01/12/2018",
        dataEnd: "01/12/2050",
      },

      listTypeActivity: [],
    };
  },

  /* computed: {
    listTypeActivity() {
      return this.$store.getters["typeActivity/listActivity"];
    },
  }, */

  created() {
    this.getListTypeActivity();
  },

  methods: {
    async getListTypeActivity() {
      try {
        await this.$store.dispatch("typeActivity/getTypeActivity").then(() => {
          this.listTypeActivity = this.$store.getters[
            "typeActivity/listActivity"
          ];
          this.loading = true;
          /*   console.log("listTypeActivity"); */
          return this.listTypeActivity;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getContactsPage" });
      }
    },

    setFilter() {
      this.activeFilter = { ...this.getDataFilter };
      this.$emit("change-filter", this.activeFilter);
    },

    getDate(updateRange) {
      this.getDataFilter.dataStart = new Date(updateRange.start);
      this.getDataFilter.dataEnd = new Date(updateRange.end);
    },
  },
};
</script>
