<template>
  <div>
    <div class="row no-gutters">
      <div class="col-lg-4 col-xl-3 order-2 order-lg-1">
        <section class="o-filter">
          <b-button
            v-b-toggle.filtri
            variant="primary"
            class="d-block d-lg-none mt-1 mb-1 w-100"
            >Filtra risultati</b-button
          >
          <b-collapse visible accordion id="filtri" class="mt-0 o-filter">
            <app-activity-filter @change-filter="Filter"></app-activity-filter>
          </b-collapse>
        </section>
      </div>
      <div class="col-12 col-lg-8 col-xl-9 order-1 order-lg-2">
        <div class="row no-gutters">
          <b-skeleton-wrapper :loading="loading">
            <div class="col-12 pl-2 pr-2">
              <b-skeleton
                width="25%"
                animation="wave"
                class="mt-4 mb-4"
              ></b-skeleton>

              <b-skeleton-table
                :rows="5"
                :columns="4"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>
          </b-skeleton-wrapper>
          <app-activity-table
            v-if="loading == true"
            :getItems="activityList"
            :getRow="activityList.length"
          ></app-activity-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppActivityTable from "../components/AppActivityTable.vue";
import AppActivityFilter from "../components/AppActivityFilter.vue";
import moment from "moment";
export default {
  components: { AppActivityTable, AppActivityFilter },

  name: "Attivita",
  data() {
    return {
      loading: false,
      payload: [],
      lastFilter: {
        search: "",
        checkedType: [],
        dataStart: new Date("01/01/2018"),
        dataEnd: new Date("12/31/2050"),
      },
      masks: {
        input: "DD/MM/YYYY",
        output: "DD/MM/YYYY",
      },
    };
  },

  computed: {
    hasActivity() {
      return this.$store.getters["activity/hasActivity"];
    },

    activityListRow() {
      return this.$store.getters["activity/nrItem"];
    },

    activityList() {
      /*  console.log(this.loading); */
      const activityGet = this.$store.getters["activity/activity"];
      /*     console.log(activityGet); */
      return activityGet.filter((activityGet) => {
        const lineDataStart = moment(
          String(activityGet.activity.startDt)
        ).format();
        const lineDataEnd = moment(String(activityGet.activity.endDt)).format();
        const filterDataStart = moment(
          String(this.lastFilter.dataStart)
        ).format();
        const filterDataEnd = moment(String(this.lastFilter.dataEnd)).format();

        if (
          activityGet.activity.name
            .toLowerCase()
            .includes(this.lastFilter.search.toLowerCase()) &&
          lineDataStart >= filterDataStart &&
          lineDataEnd <= filterDataEnd
        ) {
          let togle = true;
          if (this.lastFilter.checkedType.length > 0) {
            togle = false;
            this.lastFilter.checkedType.forEach((element) => {
              if (element === activityGet.activity.activityType_Code) {
                togle = true;
              }
            });
          }

          if (togle === true) {
            return true;
          }
        }
        return false;
      });
    },
  },

  mounted() {
    /* this.$store.dispatch("activity/getActivityPage", 1); */
    this.$store.dispatch("activity/getActivity");
    /*   this.activityList(); */
  },
  created() {
    this.getActivity();
  },
  methods: {
    Filter(updateFilter) {
      /*       console.log(updateFilter); */
      this.lastFilter = updateFilter;
    },

    async getActivity() {
      try {
        await this.$store.dispatch("activity/getActivity").then(() => {
          this.payload = this.$store.getters["activity/activity"];
          this.loading = true;

          /*   this.activityList(); */
          return;
        });
      } catch (e) {
        this.loading = false;
        console.log({ message: "Some error getActivity" });
      }
    },
  },
};
</script>
