<template>
    <div class="col-12 pl-2 pr-2 h-100">
        <div class="row no-gutters pt-2 pb-2">
            <div class="col-md-6">
                <p class="m-list-title">Attività</p>
            </div>
            <div class="col-md-6 ">
                <div class="d-flex justify-content-md-end align-items-center h-100">
                    <b-button variant="primary" size="md" v-b-modal.newActivity-id>
                        Nuova attività
                    </b-button>
                </div>
            </div>
        </div>

        <b-table
            :items="getItems"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            stacked="md"
            show-empty
            small
            striped
            id="AppActivityTable"
            thead-tr-class="m-table"
        >
            <template #cell(type)="row">
                <b-badge class="m-badge">
                    <span>{{ row.item.activity.activityType_Code }}</span>
                </b-badge>
            </template>
            <template #cell(data)="row">
                {{ chageFormatDate(row.item.activity.startDt) }} -
                {{ chageFormatDate(row.item.activity.endDt) }}
            </template>
            <!-- <template #cell(dueDate)="row">
                {{ chageFormatDate(row.item.activity.dueDt) }}
            </template> -->
            <template #cell(Nome)="row">
                {{ row.item.activity.name | truncate(50) }}
                <!--  {{ row.item.activity.id }} -->
            </template>
            <!-- <template #cell(sendEmail)="row">
                <b-form-group label="" :label-for="`send-email-${row.item.activity.id}`" class="m-input">
                    <b-form-checkbox :id="`send-email-${row.item.activity.id}`" value-field="true" />
                </b-form-group>
            </template> -->
            <template #cell(actions)="row">
                <b-button
                    size="sm"
                    :to="{
                        name: 'DettagliAttivita',
                        query: { id: row.item.activity.id },
                    }"
                    class="mr-1"
                >
                    Modifica
                </b-button>
                <b-button
                    size="sm"
                    variant="danger"
                    :disabled="row.item.activity.contactsCount > 0"
                    @click="ModalDeleteActivityById(row.item.activity.id)"
                >
                    Elimina
                </b-button>
            </template>
        </b-table>
        <b-modal
            id="newActivity-id"
            title="Nuova attività"
            @hide="resetModal"
            ref="modal"
            centered
            @ok="handleOk"
            ok-only
            ok-title="Salva"
            header-class="text-primary"
            body-class="pb-0"
            footer-class="d-flex justify-content-start border-0 pt-0"
        >
            <form ref="form" @submit.stop.prevent="saveLine()">
                <b-form-group label="Nome" label-for="name-input" class="m-input">
                    <b-form-input id="name-input" v-model="newActivity.name" maxlength="100"></b-form-input>
                </b-form-group>

                <b-form-group label="Tipologia" label-for="name-select" class="m-input">
                    <b-form-select
                        id="name-select"
                        v-model="newActivity.activityType_Code"
                        :options="listTypeActivity"
                        value-field="activity.id"
                        text-field="activity.name"
                    />
                </b-form-group>

                <v-date-picker class="" v-model="newActivity.startDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group label="Data inizio" label-for="input-day" class="m-input">
                            <input id="input-day" class="form-control" :value="inputValue" v-on="inputEvents" />
                        </b-form-group>
                    </template>
                </v-date-picker>

                <v-date-picker class="" v-model="newActivity.endDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group label="Data fine" label-for="input-dayEnd" class="m-input">
                            <input id="input-dayEnd" class="form-control" :value="inputValue" v-on="inputEvents" />
                        </b-form-group>
                    </template>
                </v-date-picker>

                <!-- <v-date-picker class="" v-model="newActivity.dueDt" :masks="masks">
                    <template v-slot="{ inputValue, inputEvents }">
                        <b-form-group label="Data di scadenza per cancellazioni" label-for="input-dayDue" class="m-input">
                            <input id="input-dayDue" class="form-control" :value="inputValue" v-on="inputEvents" />
                        </b-form-group>
                    </template>
                </v-date-picker> -->

                <!-- <b-form-group label="Con invio email" label-for="new-activity-send-email" class="m-input">
                    <b-form-checkbox id="new-activity-send-email" value-field="true" @click.prevent="(e) => handleNewActivitySendEmail(e)" />
                </b-form-group>

                <b-form-group v-if="showEmailFields === true" label="Email mittente" label-for="email-sender-input" class="m-input">
                    <b-form-input id="email-sender-input" v-model="newActivity.mailFrom" type="email"></b-form-input>
                </b-form-group>

                <b-form-group v-if="showEmailFields === true" label="Nome" label-for="email-subject-input" class="m-input">
                    <b-form-input id="email-subject-input" v-model="newActivity.mailSubject" type="text"></b-form-input>
                </b-form-group>

                <b-form-group v-if="showEmailFields === true" label="Nome" label-for="email-message-input" class="m-input">
                    <b-form-textarea id="email-message-input" v-model="newActivity.mailBody"></b-form-textarea>
                </b-form-group> -->
            </form>
        </b-modal>
        <b-modal
            id="modal-delete"
            @ok="handleOkDelete"
            size="sm"
            button-size="sm"
            buttonSize="sm"
            okVariant="danger"
            okTitle="YES"
            cancelTitle="NO"
            footerClass="p-2"
            hideHeaderClose centered
        >
            <p><strong>Conferma eliminazione</strong></p>
        </b-modal>
        <b-pagination
            v-model="currentPage"
            :align="`center`"
            aria-controls="AppActivityTable"
            :per-page="perPage"
            size="sm"
            :total-rows="getRow"
        ></b-pagination>
    </div>
</template>

<script>
import moment from "moment";
export default {
    name: "AppActivityTable",
    props: ["getItems", "getRow", "getFilter"],

    data() {
        return {
            fields: [
                {
                    key: "type",
                    label: "Tipologia",
                    sortable: false,
                    thClass: "",
                    tdClass: "pl-3",
                },
                {
                    key: "data",
                    label: "Data inizio / fine",
                    sortable: false,
                },
                // {
                //     key: "dueDate",
                //     label: "Data scadenza per cancellazioni",
                //     sortable: false,
                // },
                {
                    key: "Nome",
                    label: "Nome attività",
                    sortable: true,
                },
                {
                    key: "activity.contactsCount",
                    label: "N. Contatti",
                    sortable: true,
                },
                // {
                //     key: 'sendEmail',
                //     label: 'Con invio email',
                //     sortable: false,
                // },
                {
                    key: "actions",
                    label: "",
                    sortable: false,
                    thClass: "",
                    tdClass: "d-flex justify-content-end ",
                },
            ],

            itemDelete: "",

            // Inizializazione tabella
            sortBy: "Ord",
            sortDesc: false,
            totalRows: 1,
            currentPage: 1,
            perPage: 20,

            newActivity: {
                Day: moment().format(),
                DayEnd: moment().format(),
            },

            showEmailFields: false,

            masks: {
                input: "DD/MM/YYYY",
                output: "DD/MM/YYYY",
            },
        };
    },

    filters: {
        truncate: function (value, size) {
            if (!value) return "";
            value = value.toString();

            if (value.length <= size) {
                return value;
            }
            return value.substr(0, size) + "...";
        },
    },

    computed: {
        listTypeActivity() {
            return this.$store.getters["typeActivity/listActivity"];
        },

        numberPerPage() {
            return this.$store.getters["activity/perPage"];
        },
    },

    methods: {
        //svuoto la modal
        resetModal() {
            this.newActivity = [];
        },

        ModalDeleteActivityById($id) {
            this.$bvModal.show("modal-delete");
            this.itemDelete = $id;
        },

        saveLine: function () {
            /* console.log(this.newActivity);
            console.log({
            name: this.newActivity.name,
            activityType_Code: this.newActivity.activityType_Code,
            startDt: moment(String(this.newActivity.startDt)).format(),
            endDt: moment(String(this.newActivity.endDt)).format(),
            }); */

            var data = {
                name: this.newActivity.name,
                code: this.newActivity.name.length,
                activityType_Code: this.newActivity.activityType_Code,
                startDt: moment(String(this.newActivity.startDt)).format(),
                endDt: moment(String(this.newActivity.endDt)).format(),
                // dueDt: moment(String(this.newActivity.dueDt)).format(),
            };

            this.$store.dispatch("activity/postActivty", data);

            /* if (this.newActivity.Nome && this.newActivity.Type) {
              this.activityList.push({
                id: 2,
                name: this.newActivity.Nome,
                day: moment(String(this.newActivity.Day)).format("DD/MM/YYYY"),
                dayEnd: moment(String(this.newActivity.DayEnd)).format("DD/MM/YYYY"),
                type: this.newActivity.Type,
                contactList: [],
                list: 0,
              });
            } */

            this.$nextTick(() => {
                this.$bvModal.hide("newActivity-id");
            });

            /* console.log(this.activityList); */
        },

        handleOk(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.saveLine();
        },

        /* typeActivityById($id) {
          return this.$store.getters["typeActivity/typeActivityById"]($id).Codice;
        }, */

        async handleOkDelete() {
            /* console.log($id);
            console.log(this.getItems[$id]); */
            /* const $idActivity = this.getItems[$id].idActivity; */
            console.log(this.itemDelete);

            try {
                await this.$store
                    .dispatch("activity/deleteActivty", this.itemDelete)
                    .then(() => {
                        console.log("attività eliminata");
                        this.$router.go();
                    });
            } catch (e) {
                this.loading = false;
                console.log({ message: "Some error deleteContactsActivity" });
            }

            return;
        },

        /* deleteRow($id) {
          console.log(this.getItems);
          console.log($id);
           this.$store.dispatch("activity/deleteActivty", $id);
        }, */

        chageFormatDate($date) {
            return moment(new Date($date), "YYYY-MM-DD").format("DD/MM/YYYY");
        },

        // handleNewActivitySendEmail(e) {
        //     if (e.currentTarget.getAttribute('checked') === true) {
        //         this.showEmailFields = true;

        //         return;
        //     }

        //     this.showEmailFields = false;
        // }
    },

    watch: {
        /* currentPage(newPage) {
          this.$store.dispatch("activity/getActivityPage", newPage);
        }, */
    },
};
</script>
