var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 pl-2 pr-2 h-100"},[_c('div',{staticClass:"row no-gutters pt-2 pb-2"},[_vm._m(0),_c('div',{staticClass:"col-md-6 "},[_c('div',{staticClass:"d-flex justify-content-md-end align-items-center h-100"},[_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.newActivity-id",modifiers:{"newActivity-id":true}}],attrs:{"variant":"primary","size":"md"}},[_vm._v(" Nuova attività ")])],1)])]),_c('b-table',{attrs:{"items":_vm.getItems,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"stacked":"md","show-empty":"","small":"","striped":"","id":"AppActivityTable","thead-tr-class":"m-table"},scopedSlots:_vm._u([{key:"cell(type)",fn:function(row){return [_c('b-badge',{staticClass:"m-badge"},[_c('span',[_vm._v(_vm._s(row.item.activity.activityType_Code))])])]}},{key:"cell(data)",fn:function(row){return [_vm._v(" "+_vm._s(_vm.chageFormatDate(row.item.activity.startDt))+" - "+_vm._s(_vm.chageFormatDate(row.item.activity.endDt))+" ")]}},{key:"cell(Nome)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("truncate")(row.item.activity.name,50))+" ")]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","to":{
                    name: 'DettagliAttivita',
                    query: { id: row.item.activity.id },
                }}},[_vm._v(" Modifica ")]),_c('b-button',{attrs:{"size":"sm","variant":"danger","disabled":row.item.activity.contactsCount > 0},on:{"click":function($event){return _vm.ModalDeleteActivityById(row.item.activity.id)}}},[_vm._v(" Elimina ")])]}}])}),_c('b-modal',{ref:"modal",attrs:{"id":"newActivity-id","title":"Nuova attività","centered":"","ok-only":"","ok-title":"Salva","header-class":"text-primary","body-class":"pb-0","footer-class":"d-flex justify-content-start border-0 pt-0"},on:{"hide":_vm.resetModal,"ok":_vm.handleOk}},[_c('form',{ref:"form",on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.saveLine()}}},[_c('b-form-group',{staticClass:"m-input",attrs:{"label":"Nome","label-for":"name-input"}},[_c('b-form-input',{attrs:{"id":"name-input","maxlength":"100"},model:{value:(_vm.newActivity.name),callback:function ($$v) {_vm.$set(_vm.newActivity, "name", $$v)},expression:"newActivity.name"}})],1),_c('b-form-group',{staticClass:"m-input",attrs:{"label":"Tipologia","label-for":"name-select"}},[_c('b-form-select',{attrs:{"id":"name-select","options":_vm.listTypeActivity,"value-field":"activity.id","text-field":"activity.name"},model:{value:(_vm.newActivity.activityType_Code),callback:function ($$v) {_vm.$set(_vm.newActivity, "activityType_Code", $$v)},expression:"newActivity.activityType_Code"}})],1),_c('v-date-picker',{attrs:{"masks":_vm.masks},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('b-form-group',{staticClass:"m-input",attrs:{"label":"Data inizio","label-for":"input-day"}},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"input-day"},domProps:{"value":inputValue}},inputEvents))])]}}]),model:{value:(_vm.newActivity.startDt),callback:function ($$v) {_vm.$set(_vm.newActivity, "startDt", $$v)},expression:"newActivity.startDt"}}),_c('v-date-picker',{attrs:{"masks":_vm.masks},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var inputValue = ref.inputValue;
                var inputEvents = ref.inputEvents;
return [_c('b-form-group',{staticClass:"m-input",attrs:{"label":"Data fine","label-for":"input-dayEnd"}},[_c('input',_vm._g({staticClass:"form-control",attrs:{"id":"input-dayEnd"},domProps:{"value":inputValue}},inputEvents))])]}}]),model:{value:(_vm.newActivity.endDt),callback:function ($$v) {_vm.$set(_vm.newActivity, "endDt", $$v)},expression:"newActivity.endDt"}})],1)]),_c('b-modal',{attrs:{"id":"modal-delete","size":"sm","button-size":"sm","buttonSize":"sm","okVariant":"danger","okTitle":"YES","cancelTitle":"NO","footerClass":"p-2","hideHeaderClose":"","centered":""},on:{"ok":_vm.handleOkDelete}},[_c('p',[_c('strong',[_vm._v("Conferma eliminazione")])])]),_c('b-pagination',{attrs:{"align":"center","aria-controls":"AppActivityTable","per-page":_vm.perPage,"size":"sm","total-rows":_vm.getRow},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-md-6"},[_c('p',{staticClass:"m-list-title"},[_vm._v("Attività")])])}]

export { render, staticRenderFns }