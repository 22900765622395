<template>
  <div class="mb-4">
    <label class="text-primary font-weight-bold mb-1 mt-2 o-filter__label"
      >Periodo</label
    >
    <!--<v-date-picker
      v-model="range"
      mode="date"
      :masks="masks"
      is-range
      @input="setDate"
    >
      <template v-slot="{ inputValue, inputEvents, isDragging }">
        <div class="row no-gutters">
          <div class="col-lg-6">
            <b-input-group size="" class="mt-2 mt-lg-0">
              <input
                class="form-control"
                :class="isDragging ? 'text-gray-600' : 'text-gray-900'"
                :value="inputValue.start"
                v-on="inputEvents.start"
                id="startDate"
              />
              <b-input-group-append is-text>
                <b-icon variant="primary" icon="calendar"></b-icon>
              </b-input-group-append>
            </b-input-group>
          </div>
          <div class="col-lg-6">
            <b-input-group size="" class="mt-2 mt-lg-0">
              <input
                class="form-control"
                :class="isDragging ? 'text-primary' : 'text-gray-900'"
                :value="inputValue.end"
                v-on="inputEvents.end"
                id="endDate"
              />
              <b-input-group-append is-text>
                <b-icon variant="primary" icon="calendar"></b-icon>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </template>
    </v-date-picker>-->
    <div class="row no-gutters">
      <div class="col-lg-6">
        <v-date-picker
          class=""
          v-model="range.start"
          :masks="masks"
          @input="setDate"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-form-group
              label="Data inizio"
              label-for="input-day"
              class="m-input"
            >
              <input
                id="input-day"
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </b-form-group>
          </template>
        </v-date-picker>
      </div>
      <div class="col-lg-6">
        <v-date-picker
          class=""
          v-model="range.end"
          :masks="masks"
          @input="setDate"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-form-group
              label="Data fine"
              label-for="input-dayEnd"
              class="m-input"
            >
              <input
                id="input-dayEnd"
                class="form-control"
                :value="inputValue"
                v-on="inputEvents"
              />
            </b-form-group>
          </template>
        </v-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  emits: ["change-range"],
  name: "DataRange",
  data() {
    return {
      range: {
        start: moment()
          .subtract(2, "year")
          .format(),
        end: new Date(),
      },
      masks: {
        input: "DD/MM/YYYY",
        output: "DD/MM/YYYY",
      },
    };
  },
  methods: {
    setDate() {
      const updateRange = this.range;
      this.range = updateRange;
      /*  console.log(updateRange); */
      this.$emit("change-range", updateRange);
    },
  },
};
</script>
